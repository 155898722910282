import { replaceStringWith } from './index';

export const getFormatedAuthorUrl = (
  authorId: string,
  authorName: string
): string => {
  const titleNoSpecialChars = replaceStringWith(
    authorName,
    /[^a-zA-Z0-9 ]/g,
    ''
  );
  return `${authorId}-${titleNoSpecialChars.replace(/\s+/g, '-')}`;
};
